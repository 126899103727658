import React from "react";
import { Col, Grid } from "rsuite";

import appstore from '../assets/images/appstore.png';
import playstore from '../assets/images/playstore.png';
import footer from '../assets/images/footer.png';
import logo from '../assets/images/logo_t.png';


import { Link } from "react-router-dom";

import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare';
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {youtubePlay} from 'react-icons-kit/fa/youtubePlay';
import {whatsapp} from 'react-icons-kit/fa/whatsapp';
import {blogger} from 'react-icons-kit/icomoon/blogger';
import {instagram} from 'react-icons-kit/fa/instagram';
import {copyright} from 'react-icons-kit/fa/copyright'
import Icon from "react-icons-kit";


const Footer = ({device, t, lang})=>{
    
    return(
        <Grid fluid id="footer">
            {device === 'mobile' ?
                <Col xs={24}>
                    <Col xs={22} xsOffset={1} md={16} mdOffset={4} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer" style={{paddingBottom:'3%'}}>
                        <h1 className='text-center' >{t('pages.home.download')}</h1>
                        <div className="titleLine"></div>
                    </Col>
                    <Col xs={24} className="mt-3 mb-3">
                        <Col xs={24} sm={20} smOffset={2} md={14} mdOffset={5}>
                            <Col className="mb-3 text-center" xs={4} lg={3} lgOffset={5} xl={2} xlOffset={6}>
                                <a href="https://www.linkedin.com/company/justcheckus" target="_blank" rel="noreferrer">
                                    <Icon icon={linkedinSquare} size={40} style={{color:'var(--azul)'}} />
                                </a>                                
                            </Col>
                            <Col className="mb-3 text-center" xs={4}  lg={3} xl={2}>
                                <a href="https://www.facebook.com/Justcheck.us" target="_blank" rel="noreferrer">
                                    <Icon icon={facebookSquare} size={40} style={{color:'var(--azul)'}} />
                                </a>                                
                            </Col>
                            <Col className="mb-3 text-center" xs={4}  lg={3} xl={2}>
                                <a href="https://www.youtube.com/channel/UCVj6JC8ovlhW9DLk9S-LJ6g/videos" target="_blank" rel="noreferrer">
                                    <Icon icon={youtubePlay} size={40} style={{color:'var(--azul)'}} />
                                </a>                                
                            </Col>
                            <Col className="mb-3 text-center" xs={4}  lg={3} xl={2}>
                                <a href="tel:+525621212282" target="_blank" rel="noreferrer">
                                    <Icon icon={whatsapp} size={40} style={{background:'var(--azul)', color:'#FFF', borderRadius:'20px 20px 20px 0px'}}/>
                                </a>                                
                            </Col>
                            <Col className="mb-3 text-center" xs={4}  lg={3} xl={2}>
                                <a href="https://www.instagram.com/justcheck.us/" target="_blank" rel="noreferrer">
                                    <Icon icon={instagram} size={40} style={{background:'var(--azul)', color:'#FFF', borderRadius:13}}/>
                                </a>                                
                            </Col>
                            <Col className="mb-3 text-center" xs={4}  lg={3} xl={2}>
                                <a href="https://blog.justcheck.us" target="_blank" rel="noreferrer">
                                    <Icon icon={blogger} size={40} style={{color:'var(--azul)'}} />
                                </a>                                
                            </Col>
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col xs={20} xsOffset={2} sm={10} smOffset={7} md={10} mdOffset={7} lg={7} xl={6} xlOffset={1} className="mt-3 mb-3">
                            <img src={footer} style={{width:'100%', height:'100%'}} alt="layout" />
                        </Col>                            
                    </Col>
                    <Col xs={24} className="mt-3 mb-3">
                        <Col xs={24} sm={18} smOffset={3} md={18} mdOffset={3} lg={16} lgOffset={10} xl={12} xlOffset={10}>
                            <Col xs={12} lg={12} xl={12}>
                                <a href="https://apps.apple.com/app/apple-store/id1578101509" target="_blank" rel="noreferrer">
                                    <img src={appstore} style={{width:'100%', height:'100%'}} alt="layout" />
                                </a>
                            </Col>
                            <Col xs={12} lg={12} xl={12}>
                                <a href="https://play.google.com/store/apps/details?id=com.justcheck&hl=es_MX" target="_blank" rel="noreferrer">
                                    <img src={playstore} style={{width:'100%', height:'100%'}} alt="layout" />
                                </a>
                            </Col>
                        </Col>
                    </Col>
                    
                    <Col xs={24}>
                        <Col xs={24} sm={24} md={24}>
                            <h4 style={{textAlign:'center', fontSize:20, fontWeight:700, fontStyle:'normal', color:'var(--naranja)'}}>{t('footer.leyend')}</h4>
                        </Col>
                    </Col>
                </Col>
           
            :
                <Col xs={24}>
                    <Col xs={22} lg={14} lgOffset={5} xl={16} xlOffset={4}>                                    
                        <Col xs={24}>
                            <Col md={8} lg={7} xl={6} xlOffset={1} style={{position:'absolute', zIndex:1, top:80}}>
                                <img src={footer} style={{width:'100%', height:'100%'}} alt="layout" />
                            </Col>                            
                        </Col>
                        <Col xs={24}>
                            <Col lg={16} lgOffset={10} xl={12} xlOffset={10} className="titleContainer">
                                <h1 className='text-center' >{t('pages.home.download')}</h1>
                                <div className="titleLine"></div>
                            </Col>
                        </Col>   
                        <Col xs={24} className="mt-3 mb-3">
                            <Col lg={16} lgOffset={10} xl={12} xlOffset={10} >
                                <Col className="mb-3 text-center" xs={4} lg={4} xl={3} xlOffset={3} >
                                    <a href="https://www.linkedin.com/company/justcheckus" target="_blank" rel="noreferrer">
                                        <Icon icon={linkedinSquare} size={40} style={{color:'var(--azul)'}}/>
                                    </a>
                                </Col>
                                <Col className="mb-3 text-center" xs={4}  lg={4} xl={3}>
                                    <a href="https://www.facebook.com/Justcheck.us" target="_blank" rel="noreferrer">
                                        <Icon icon={facebookSquare} size={40} style={{color:'var(--azul)'}}/>
                                    </a>                                    
                                </Col>
                                <Col className="mb-3 text-center" xs={4}  lg={4} xl={3}>
                                    <a href="https://www.youtube.com/channel/UCVj6JC8ovlhW9DLk9S-LJ6g/videos" target="_blank" rel="noreferrer">
                                        <Icon icon={youtubePlay} size={40} style={{color:'var(--azul)'}}/>
                                    </a>                                    
                                </Col>
                                <Col className="mb-3 text-center" xs={4}  lg={4} xl={3}>
                                    <a href="tel:+525621212282" target="_blank" rel="noreferrer">
                                        <Icon icon={whatsapp} size={40} style={{background:'var(--azul)', color:'#FFF', borderRadius:'20px 20px 20px 0px'}}/>
                                    </a>                                    
                                </Col>
                                <Col className="mb-3 text-center" xs={4}  lg={4} xl={3}>
                                    <a href="https://www.instagram.com/justcheck.us/" target="_blank" rel="noreferrer">
                                        <Icon icon={instagram} size={40} style={{background:'var(--azul)', color:'#FFF', borderRadius:13}}/>
                                    </a>                                    
                                </Col>
                                <Col className="mb-3 text-center" xs={4}  lg={4} xl={3}>
                                    <a href="https://blog.justcheck.us" target="_blank" rel="noreferrer">
                                        <Icon icon={blogger} size={40} style={{color:'var(--azul)'}}/>
                                    </a>                                    
                                </Col>
                            </Col>
                        </Col>

                        <Col xs={24} className="mb-3">
                            <Col xs={24} lg={16} lgOffset={10} xl={12} xlOffset={10}>
                                <Col xs={12} lg={12} xl={12}>
                                    <a href="https://apps.apple.com/app/apple-store/id1578101509" target="_blank" rel="noreferrer">
                                        <img src={appstore} style={{width:'100%', height:'100%'}} alt="layout" />
                                    </a>
                                </Col>
                                <Col xs={12} lg={12} xl={12}>
                                    <a href="https://play.google.com/store/apps/details?id=com.justcheck&hl=es_MX" target="_blank" rel="noreferrer">
                                        <img src={playstore} style={{width:'100%', height:'100%'}} alt="layout" />
                                    </a>
                                </Col>
                            </Col>
                        </Col>

                        
                        
                        <Col xs={24}>
                            <Col lg={16} lgOffset={10} xl={12} xlOffset={10} className="text-center">
                                <h4 style={{fontSize:20, fontWeight:700, fontStyle:'normal', color:'var(--naranja)'}}>{t('footer.leyend')}</h4>
                            </Col>
                        </Col>        
                    </Col>
                </Col>
            }

            <Col xs={24} className="footer">
                <Col xs={22} xsOffset={1} md={22} mdOffset={1} lg={20} lgOffset={2} xl={16} xlOffset={4}>
                    <Col lg={17} lgOffset={9} xl={22} xlOffset={8} style={{marginTop:35, marginBottom:'10%'}}>
                        <Col xs={24} sm={8} lg={8} xl={8} className="pb-3">
                            <h2 style={{fontSize:20, fontWeight:600}}>{t('footer.review')}</h2>
                            <div style={{marginTop:15}} className="flex">
                                <Col xs={12} sm={12} md={12} lg={10}>
                                    <a href="https://sourceforge.net/software/product/JustCheck/?pk_campaign=badge&amp;pk_source=vendor" target="_blank" rel="noreferrer">
                                        <img alt="justCheck Reviews" src="https://b.sf-syn.com/badge_img/3547390/customers-love-us-white?&amp;variant_id=sf&amp;r=https://www.justcheck.us/es/index.html" 
                                            style={{width:'100%'}} 
                                        />
                                    </a>
                                </Col>
                                
                            </div>
                        </Col>
                        <Col xs={24} sm={8} lg={8} xl={8} className="pb-3">
                            <h2 style={{fontSize:20, fontWeight:600}}>{t('footer.contact')}</h2>
                            <div style={{marginTop:15}}>
                                <h3 style={{fontSize:14, fontWeight:500}}>
                                    <Link to={lang+"/sales"} style={{color:'var(--white)'}}>{t('footer.sales')}</Link>
                                </h3>
                                <h3 style={{fontSize:14, fontWeight:500}}>
                                    <Link to={lang+"/support"} style={{color:'var(--white)'}}>{t('footer.support')}</Link>
                                </h3>
                            </div>
                        </Col>
                        <Col  xs={24} sm={8}  lg={8} xl={8} className="pb-3">
                            <h2 style={{fontSize:20, fontWeight:600}}>JustCheck, Inc</h2>
                            <div style={{marginTop:15}}>
                                <h3 style={{fontSize:14, fontWeight:500}}>2810 N Church ST</h3>
                                <h3 style={{fontSize:14, fontWeight:500}}>PM 79318</h3>
                                <h3 style={{fontSize:14, fontWeight:500}}>Wilmington, Delaware 19802-4447 USA</h3>
                                <Col xs={24}>
                                    <img src={logo} alt="logo" className="img-fluid" />
                                    <span className="ms-2" style={{fontSize:20}}>JustCheck</span>
                                </Col>
                            </div>
                        </Col>
                    </Col>
                </Col>

                <Col xs={24} className="m-2 flex justify-content-center" >
                    <Col xs={24} sm={24} md={20} lg={18} xl={16} className="text-center">                        
                        <Icon icon={copyright} />
                        <span className="ms-2 me-2">JustCheck, Inc</span>                    
                        <a href="https://justcheck-files.s3.amazonaws.com/privacy-policy.html" className="ms-2 me-2">{t('footer.policy')}</a>                    
                        <a href="https://justcheck-files.s3.amazonaws.com/terms_of_use.html" className="ms-2 me-2">{t('footer.terms')}</a>                    
                    </Col>
                </Col>
            </Col>
        </Grid>
    )
}

export default Footer;