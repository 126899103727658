import React from "react";
import { Col, Grid } from "rsuite"

import register from '../../assets/images/register.png';
import facial from '../../assets/images/facial.png';
import location from '../../assets/images/location.png';

const Features = ({styles, device, t}) => {
    const features = 'pages.home.features';
    return(
        <Grid fluid id="features" className={styles.features}>
            <Col xs={24}>
                <Col xs={24} md={20} mdOffset={2} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer">
                    <h1 className='text-center' >{t(features+'.title')}</h1>
                    <div className="titleLine"></div>
                </Col>
            </Col>
            <Col xs={24} className={styles.featuresContent}>
                <Col md={22} mdOffset={1} lg={22} lgOffset={2} xl={18} xlOffset={3}>
                    <Col xs={24} md={24} lg={8}  className={styles.register+' mb-3'}>                        
                        <Col xs={8} sm={5} md={5} lg={6} xl={6}>
                            <img src={register} alt="register" style={{width:'100%', height:'100%'}}/>
                        </Col>
                        <Col xs={24} sm={19} md={19} lg={18} xl={18}>
                            <h2>{t(features+'.register.title')}</h2>
                            {device === 'mobile' &&
                                <>
                                    <Col xs={24} className={styles.line}></Col>
                                    <Col xs={24}>
                                        <p>
                                            {t(features+'.register.content')}
                                        </p>
                                    </Col>
                                </>
                            }
                            
                        </Col>
                        {device === 'desktop' &&
                            <>
                                <Col lg={24} className={styles.line}></Col>
                                <Col lg={24}>
                                    <p>
                                       {t(features+'.register.content')}
                                    </p>
                                </Col>
                            </>
                        }
                        
                    </Col>

                    <Col xs={24} md={24} lg={8}  className={styles.register+' mb-3'}>                        
                        <Col xs={8} sm={5} md={5} lg={6} xl={6}>
                            <img src={facial} alt="register" style={{width:'100%', height:'100%'}}/>
                        </Col>
                        <Col xs={24} sm={19} md={19} lg={18} xl={18}>
                            <h2>{t(features+'.facial.title')}</h2>
                            {device === 'mobile' &&
                                <>
                                    <Col xs={24} className={styles.line}></Col>
                                    <Col xs={24}>
                                        <p>
                                            {t(features+'.facial.content')}
                                        </p>
                                    </Col>
                                </>
                            }
                        </Col>
                        {device === 'desktop' &&
                            <>
                                <Col lg={24} className={styles.line}></Col>
                                <Col lg={24}>
                                    <p>
                                       {t(features+'.facial.content')}
                                    </p>
                                </Col>
                            </>
                        }
                    </Col>

                    <Col xs={24}  md={24}  lg={8}  className={styles.register+' mb-3'}>                        
                        <Col xs={8} sm={5} md={5} lg={6} xl={6}>
                            <img src={location} alt="register" style={{width:'100%', height:'100%'}}/>
                        </Col>
                        <Col xs={24} sm={19} md={19} lg={18} xl={18}>
                            <h2>{t(features+'.geolocation.title')}</h2>
                            {device === 'mobile' &&
                                <>
                                    <Col xs={24} className={styles.line}></Col>
                                    <Col xs={24}>
                                        <p>
                                           {t(features+'.geolocation.content')}
                                        </p>
                                    </Col>
                                </>
                            }
                        </Col>
                        {device === 'desktop' &&
                            <>
                                <Col lg={24} className={styles.line}></Col>
                                <Col lg={24}>
                                    <p>
                                        {t(features+'.geolocation.content')}
                                    </p>
                                </Col>
                            </>
                        }
                    </Col>
                </Col>
            </Col>
        </Grid>
    )
}

export default Features;