import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		//lng:"es",
		//fallbackLng: 'es',

	    // have a common namespace used around the full app
	    ns: ['translations'],
	    defaultNS: 'translations',

	    debug: false,

	    interpolation: {
	      escapeValue: false, // not needed for react!!
	    },

	    react: {
	      wait: true
	    }
	});

	export default i18n;
