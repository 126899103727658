import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderDesktop from "./HeaderDesktop";
import $ from 'jquery';
import Footer from "./Footer";
import { Button, Col } from "rsuite";

import register from '../assets/images/register.png';
import facial from '../assets/images/facial.png';
import location from '../assets/images/location.png';
import HeaderMovile from "./HeaderMovile";

const Layout = ({device, t, i18n})=>{
    const navigate = useNavigate();
    const {state, pathname} = useLocation();
    let lang = pathname.indexOf('en') !== -1 ? 'en' : 'es';// i18n.language === 'en' ? 'en' : 'es';

    const handleScrollToElement = (id, vel = 'slow')=>{    
        if(document.getElementById(id) !== null){
            $('html,body').animate({
                scrollTop: $("#" + id).offset().top 
            }, vel);
        }
	}

    useEffect(()=>{
        if(lang === 'es'){
            i18n.changeLanguage('es');
        }else{
            i18n.changeLanguage('en');
        }
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        if(state !== null){
            if(state.section){
                handleScrollToElement(state.section, 'fast');
            }            
        }
    });

    return(
        <>
            {device === 'desktop' ?
                <HeaderDesktop i18n={i18n} device={device} t={t} scroll={handleScrollToElement} />
            :
                <HeaderMovile i18n={i18n} device={device} t={t} scroll={handleScrollToElement} />
            }
            
            <Outlet />

            <Footer t={t} device={device} lang={i18n.language === 'en' ? 'en' : 'es'}/>

            <div className="schedule">
                <Col xs={24} sm={16} md={22} lg={20} xl={20}>
                    <Col xs={24} sm={18} smOffset={3} md={14} mdOffset={5} lg={18} lgOffset={3} xl={18} xlOffset={3} className="images">
                        <Col xs={8}>
                            <img src={register} alt="register" className="img-fluid" />
                        </Col>
                        <Col xs={8}>
                            <img src={facial} alt="register" className="img-fluid" />
                        </Col>
                        <Col xs={8}>
                            <img src={location} alt="register" className="img-fluid" />
                        </Col>
                    </Col>
                    <Button style={{width:'100%'}} className="btn-demo" appearance='primary' onClick={()=>navigate('/'+lang+'/demo')}>{t('buttons.demo')}</Button>
                </Col>
                
            </div>

        </>
    )
}

export default Layout;