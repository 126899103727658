import React, { useEffect } from "react";
import { Col, Grid } from "rsuite";
import $ from 'jquery';

const Demo = ({i18n})=>{
    const loadPage = ()=>{
        let url = '';
        if(i18n.language === 'en'){
            url = 'https://meetings.hubspot.com/sales-justcheck/liga-google-meet-en-ingles';
        }else{
            url = 'https://meetings.hubspot.com/sales-justcheck/liga-google-meet-demos';
            
        }

        $('#meeting').attr('src', url+'?embed=true&amp;parentHubspotUtk=fe1c94e56db76c0ff0e476f6dc2a18ef&amp;parentPageUrl=https://www.justcheck.us/#/'+i18n.language+'/demo');

    }
    useEffect(()=>{
        loadPage();
        // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid>
            <Col xs={24}>
                <div className="meetings-iframe-container">
                    <iframe id="meeting" title="demo"  width="100%" data-hs-ignore="true" style={{minWidth: '312px', minHeight: '516px', height: '756px', border: 'none'}}></iframe>
                </div> 
            </Col>
        </Grid>
    )
}

export default Demo;