import React, { useEffect, useState } from 'react';

import { gtag, install } from 'ga-gtag';

import AppRoutes from './AppRoutes';
import { withTranslation } from 'react-i18next';

const App = (props)=>{
	const [device, setDevice] = useState('desktop');

	const getWidth = ()=>{
		if(window.innerWidth <= 1024){
            setDevice('mobile');
        }else{
            setDevice('desktop')
        }  
	}

	useEffect(()=>{
        install('AW-313674391');
        
        gtag('js', new Date()); 
        gtag('config', 'AW-313674391'); 
        gtag('config', 'G-S5QKMXYPS3');

		window.addEventListener('resize', function(){
			getWidth();
		});

		getWidth();
		
    });
	
	return(		
		<>
			<AppRoutes 
				{...props}
				device={device}				
			/>
		</>
	)
}

export default withTranslation('translations')(App);
