import React, { useState} from "react";
import { Col, Grid, Button } from "rsuite";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";

import mx from '../assets/images/mx.jpg';
import us from '../assets/images/us.png';

const HeaderDesktop = ({scroll, t, i18n})=>{
    const navigate = useNavigate();
    const location = useLocation();
    const [menu, setMenu] = useState([
        {title:'home', url:'', item:{x:0, y:0, rotate:0}, md:2, lg:2, xl:2, id:'start'},
        {title:'features', url:'', item:{x:0, y:0, rotate:0}, md:4, lg:4, xl:3, id:"features"},
        {title:'technology', url:'', item:{x:0, y:0, rotate:0}, md:4, lg:4, xl:3, id:'technology'},
        {title:'plans', url:'', item:{x:0, y:0, rotate:0}, md:4, lg:4, xl:3, id:'plans'},
        {title:'blog', url:'', item:{x:0, y:0, rotate:0}, md:2, lg:2, xl:2, id:'blog'},
        {title:'industry', url:'', item:{x:0, y:0, rotate:0}, md:3, lg:2, xl:2, id:'industry'},
    ]);

    

    const onHoverMenu = (i, option)=>{
        let items = menu.map((element, index)=>{
            if(i === index){
                element.item.y = option === 'start' ? 5 : 0;
            }

            return element;
        });

        setMenu(items);
    }

    const onClickMenu = (id)=>{
        let lang = i18n.language === 'en' ? 'en' : 'es';

        if(location.pathname === '/register'){
            let state = {
                section: id
            };

            let url_string = window.location.href;
            let campaign;

            if(url_string.indexOf('?') !== -1){
                let variable = url_string.split('?')[1];
                campaign = variable.split('=')[1];
            }

            if(location.state !== null){
                if(location.state.campaign){
                    campaign = location.state.campaign;
                }
            }

            if(campaign){
                state = {
                    ...state,
                    campaign: campaign
                };
            }            
            
            if(id === 'industries'){
                navigate('/'+lang+'/'+id, {state: state});
            }else{
                navigate('/'+lang, {state: state});
            }
            
        }else if(location.pathname.length > 2){ 
            let state = {
                section: id
            };

            if(id === 'register' || id === 'industry'){
                navigate('/'+lang+'/'+id, {state: state});
            }else{
                navigate('/'+lang, {state: state});
            }
            
        }else{            
            if(id === 'register'){
                if(location.state !== null){
                    navigate(lang+'/register', {state: {campaign: location.state.campaign}});
                }else{
                    navigate(lang+'/register');
                }
            }else if(id === 'industry'){
                navigate(lang+'/industry');
            }else{
                scroll(id);
            }
            
        }        
    }

    const changeLanguage = (e = null)=>{
        if(e !== null){
            e.preventDefault();
        }        

        let lang = i18n.language;

        let path = location.pathname.split('/');

        if(lang === 'es'){
          i18n.changeLanguage('en');
          navigate('/en'+(path.length > 2 ? '/'+path[2] : ''));
          window.location.reload();
        }else{
          i18n.changeLanguage('es');
          navigate('/es'+(path.length > 2 ? '/'+path[2] : ''));
          window.location.reload();
        }
    }


    return(
        <Grid fluid className={(location.pathname === '/es' || location.pathname === '/en' ? 'home ' : '') + "menu-container"}>
            <Col xs={24} md={24} className="menu flex align-items-center">
                <div className="menu-logo">
                    <Link to="/" className="brand">
                        <img src="/logo.png" alt="Main logo"/>
                    </Link>
                </div>
                <div className="menu-content">
                    <div className="items">
                        <div className="content flex align-items-center justify-content-center">
                            {menu.map((element, i)=>
                                    <motion.div 
                                        key={i}
                                        animate={element.item}
                                        onHoverStart={(e)=>onHoverMenu(i, 'start')}
                                        onHoverEnd={()=>onHoverMenu(i, 'end')}                            
                                        onClick={()=>onClickMenu(element.id)}
                                        className="menu-item"
                                    >
                                        <span>{t('menu.'+element.title)}</span>
                                        <motion.div
                                            layout
                                            className="menu-item-line"
                                            style={{
                                                margin:element.item.y === 0 ? 0 : 3,
                                                width:element.item.y === 0 ? 0 : '93%'
                                            }}
                                        />
                                    </motion.div>
                                )}
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="content">
                            <div className="button register">
                                <Button appearance="ghost" className="btn-register" onClick={()=>onClickMenu('register')}>{t('buttons.register')}</Button>
                            </div>
                            <div className="button register">
                                <a href="https://app.justcheck.us/#/login">
                                    <Button appearance="primary" className="btn-login">{t('buttons.login')}</Button>
                                </a>
                            </div>
                            <div className="button flag">
                                {i18n.language === 'en' ? 
                                    <Link to="#" onClick={(e)=>changeLanguage(e)} title="Change language to Spanish">
                                        {<img src={mx} alt="mx" className="img-fluid"/>}
                                    </Link>
                                :
                                    <Link to="#" onClick={(e)=>changeLanguage(e)} title="Cambiar idioma a Inglés">
                                        {<img src={us} alt="us" className="img-fluid"/>}
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Grid>
    )
}

export default HeaderDesktop;