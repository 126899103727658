import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from '../assets/images/justcheck_logo.png';
const Loader = ({i18n})=>{
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        if(location.pathname === '/'){
            setTimeout(()=>{
                navigate('/'+(i18n.language === 'en' ? 'en' : 'es'));
            }, 1500);
            
        }
    })


    return(
        <div style={{
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100vh',
            zIndex:2147483648,
            background:'white'
        }}>
            <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{width:'5%', textAlign:'center'}}>
                    <img src={logo} alt="logo" style={{width:'100%'}} />
                </div>
            </div>
        </div>
    )
}

export default Loader;