import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Grid, Col } from 'rsuite';
import Layout from './components/Layout';
import Loader from './components/Loader';
import Demo from './pages/Demo';

import Home from './pages/Home/';
import Industry from './pages/Industry';
import Register from './pages/Register';
import Support from './pages/Support';

const NotFound = ({t})=>{
    return(
        <Grid fluid>
            <Col xs={24} className="flex justify-content-center align-items-center">
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Col xs={24}>
                        <h1>404</h1>
                        <h3 style={{color:'var(--naranja)'}}>{t('general.pageNotFound')}</h3>
                    </Col>
                </Col>
            </Col>
        </Grid>
    )
}

const AppRoutes = (props)=>{
    return(
        <Routes>
            <Route path={'/'} exact element={<Loader {...props} />} />
            <Route element={<Layout {...props} />}>                
                <Route path={'/es'} exact element={<Home {...props} />} />
                <Route path={'/en'} exact element={<Home {...props} />} />
                <Route path={'/es/register'} exact element={<Register {...props} />} />
                <Route path={'/en/register'} exact element={<Register {...props} />} />
                <Route path={'/es/demo'} exact element={<Demo {...props} />} />
                <Route path={'/en/demo'} exact element={<Demo {...props} />} />
                <Route path={'/es/industry'} exact element={<Industry {...props} />} />
                <Route path={'/en/industry'} exact element={<Industry {...props} />} />
                <Route path={'/es/support'} exact element={<Support {...props} />} />
                <Route path={'/en/support'} exact element={<Support {...props} />} />
                <Route path={'/es/sales'} exact element={<Support {...props} />} />
                <Route path={'/en/sales'} exact element={<Support {...props} />} />

                <Route path='*' element={<NotFound {...props} />} />
            </Route>        

            
        </Routes>
    )
}

export default AppRoutes;