import 'intl-tel-input/build/css/intlTelInput.css';
import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Grid, Loader, Message } from "rsuite";
import ReCAPTCHA from "react-google-recaptcha"
import {isEmail, isNumeric} from  'validator';
import Swal from 'sweetalert2';
import { gtag } from 'ga-gtag';
import intlTelInput from 'intl-tel-input';
import { isAlpha } from "../helper/functions";
import styles from '../assets/css/Register.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Register = ({t, i18n})=>{
    const navigate = useNavigate();
    const {state} = useLocation();
    const captchaRef = useRef(null); 
    const [inputPhone, setInputPhone] = useState(null);
    const [register, setRegister] = useState({
        email:'',
        phone:'',
        language:'',
        owner:'',
        company:'',
        promotion:''
    });
    const [errorEmail, setErrorEmail] = useState('');
    const [errorForm, setErrorForm] = useState('');
    const [saving, setSaving] = useState(false);
    // eslint-disable-next-line
    const [disablePromotion, setDisablePromotion] = useState(false);

    const handleChange = (e)=>{
        let value = e.target.value;
        let name = e.target.name;

        let form = register;

        if(name === 'email'){            
            if(value === ''){
                setErrorEmail('');
            }else{
                if(!isEmail(value)){
                    setErrorEmail(t('general.errorEmail'));
                }else{
                    setErrorEmail('');
                }
            }
        }

        if(name === 'phone'){
            if(value !== ''){
                if(!isNumeric(value)){
                    value = value.substring(0, value.length - 1);
                }else if(value.length > 10){
                    value = value.substring(0, 10);                    
                }
            }
        }

        if(name === 'owner'){
            if(value !== ''){
                if(!isAlpha(value)){
                    let newVal = value.substring(0, value.length - 1);

                    value = newVal;
                }
            }
        }

        form = {
            ...form,
            [name]:value
        }

        setRegister(form);
    }

    const onSubmit = ()=>{
        let token = captchaRef.current.getValue();
        
        setErrorForm('');
        if(token === ''){
            setErrorForm(t('general.errorCaptcha'));
        }else{
            const phoneNumber = inputPhone.getNumber();
            let phone = phoneNumber.substr(phoneNumber.length - 10);
			let code = phoneNumber.replace(phone, '');
            let valid = true;
            let campaign = '';//router.query.campaign ? router.query.campaign : '';

            let obj = {
                name: register.owner,
                email: register.email,
                phone: code+' '+phone,
                companySize: register.company,
                referedCode: register.promotion,
                language: register.language,
                campaign: campaign,
                newsletter: false
            }

            if(register.email === '' || (phoneNumber === '' || phone.length < 10)){
                setErrorForm(t('general.errorFields'));
                valid = false
            }

            if(register.owner === '' && register.email !== ""){
                obj.name = register.email.split('@')[0];
            }
            if(register.language === ""){
                obj.language = 'es';//locale;
            }
            if(register.company === ''){
                obj.companySize = '1-10';
            }

            if(valid){
                setSaving(true);
                fetch('https://api.justcheck.us/leads-api/leads',{
					method:'POST',
					body:JSON.stringify(obj),
					headers: new Headers({
						'Content-Type':'application/json',
						'Accept':'application/json'
					})
				}).then(res => {
					if(res.ok){
						return res.json();
					}else{
						setSaving(false);
						res.text().then(msg=>{
							var message = JSON.parse(msg);						
							
                            setErrorForm(message.message);
						});
					}
				}).then(response => {
					if(response !== undefined){
                        setRegister({
                            email:'',
                            phone:'',
                            language:'',
                            owner:'',
                            company:'',
                            promotion:''
                        });
                        setErrorForm('');
                        setSaving(false);

						gtag('event', 'conversion', {'send_to': 'AW-313674391/2q-bCPzoxsIDEJeVyZUB'});
						
                        Swal.fire({
                            title: t('general.messages.register.title'),
                            icon: 'success',
                            html:t('general.messages.register.content'),
                            showCloseButton: false,
                            showCancelButton: false,
                            confirmButtonColor:'var(--naranja)',
                            confirmButtonText:t('buttons.continue'),
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/');
                            } 
                        });
					}
				});
            }
        }
    }

    const setPhoneNumber = async ()=>{
        let data = await fetch('https://get.geojs.io/v1/ip/country.json')
                        .then(res => {
                            return res.json().then((r)=>{
                                return r;
                            });
                        });
        let input = document.querySelector("#phone");
        let PhoneInput = intlTelInput(input, {
            initialCountry: data.country,
            utilsScript:"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });

        setInputPhone(PhoneInput);
    }

    const getCampaign = ()=>{
        let url_string = window.location.href;
        let campaign;

        if(url_string.indexOf('?') !== -1){
            let variable = url_string.split('?')[1];
            campaign = variable.split('=')[1];
        }

        if(state !== null){
            if(state.campaign){
                campaign = state.campaign;
            }
        }
        
        if(campaign){
            let form = register;
            form = {
                ...form,
                promotion: campaign
            }
            setRegister(form);
            setDisablePromotion(true);
        }
    }

    useEffect(()=>{
        setPhoneNumber();

        getCampaign();

    // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid>
            <Col xs={24}>
                <Col xs={24} sm={20} smOffset={2} md={18} mdOffset={3} lg={20} lgOffset={2} xl={16} xlOffset={4} className="mt-4 p-4" >
                    <Grid fluid>
                        <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                            <h1 className={styles.title+' p-2'}>{t('pages.register.title')}</h1>
                            <h3 className={styles.subtitle+' pt-3 pb-3'}>{t('pages.register.subtitle')}</h3>
                            <div className='pt-3 pb-3'>
                                <ul className={styles.content}>
                                    <li>{t('pages.register.content.entry')}</li>
                                    <li>{t('pages.register.content.location')}</li>
                                    <li>{t('pages.register.content.visit')}</li>
                                    <li>{t('pages.register.content.clients')}</li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={13}>
                            <Col xs={24}>
                                {errorForm !== '' &&
                                    <Message type="error">{errorForm}</Message>
                                }
                            </Col>
                            <Col xs={24} className='mb-4'>
                                <span>{t('pages.register.form.email')} *</span>{errorEmail !== '' && <span style={{fontSize:11, color:'red', marginLeft:8}}>{errorEmail}</span>}
                                <input className='form-control form-control-sm' id="email" name="email" value={register.email} onChange={(e)=>handleChange(e)} />
                            </Col>
                            <Col xs={24} sm={24} md={12} className='mb-4'>
                                <span>{t('pages.register.form.phone')} *</span>
                                <input className='form-control form-control-sm' id="phone" name="phone" value={register.phone} onChange={(e)=>handleChange(e)} />
                            </Col>
                            <Col xs={24} sm={24} md={12} className='mb-4'>
                                <span>{t('pages.register.form.language')}</span>
                                <select className='form-control form-control-sm' id="language" name="language" value={register.language} onChange={(e)=>handleChange(e)}>
                                    <option value="">{t('general.select')}</option>
                                    <option value="es">{t('general.spanish')}</option>
                                    <option value="en">{t('general.english')}</option>
                                </select>
                            </Col>
                            <Col xs={24} className='mb-4'>
                                <span>{t('pages.register.form.owner')}</span>
                                <input className='form-control form-control-sm' id="owner" name="owner" value={register.owner} onChange={(e)=>handleChange(e)} />
                            </Col>
                            <Col xs={24} sm={24} md={12} className='mb-4'>
                                <span>{t('pages.register.form.size')}</span>
                                <select className='form-control form-control-sm' id="company" name="company" value={register.company} onChange={(e)=>handleChange(e)}>
                                    <option value="">{t('general.select')}</option>
                                    <option value="1-10">1 - 10 {t('pages.register.form.employees')}</option>
                                    <option value="11-50">11 - 50 {t('pages.register.form.employees')}</option>
                                    <option value="51-100">51 - 100 {t('pages.register.form.employees')}</option>
                                </select>
                            </Col>
                            <Col xs={24} sm={24} md={12} className='mb-4'>
                                <span>{t('pages.register.form.code')}</span>
                                <input className='form-control form-control-sm' id="promotion" name="promotion" value={register.promotion} onChange={(e)=>handleChange(e)} disabled={disablePromotion} />
                            </Col>
                            <Col xs={24}>
                                <span className={styles.conditions}>
                                {t('pages.register.form.accept')} <a target="_blank" rel="noreferrer" href="https://justcheck-files.s3.amazonaws.com/terms_of_use.html">{t('footer.terms')}</a> {t('pages.register.form.and')}
                                    <a target="_blank" rel="noreferrer" href="https://justcheck-files.s3.amazonaws.com/privacy-policy.html">{t('footer.policy')}</a>
                                </span><br/>
                                <span className={styles.conditions}>{t('pages.register.form.credit')}</span>
                            </Col>
                            
                            <Col xs={24} className="mt-3">
                                {!saving ? 
                                    <>
                                        <Grid fluid className="mt-2 mb-2">
                                            <Col xs={24} className="flex justify-content-center">
                                                {i18n.language === 'en' ?
                                                    <ReCAPTCHA 
                                                        hl='en'
                                                        sitekey='6Ldb1MYZAAAAAIfuUlv3QK-wWvwKKKafTxfwPACe'
                                                        ref={captchaRef}
                                                    />
                                                :
                                                    <ReCAPTCHA 
                                                        hl='es'
                                                        sitekey='6Ldb1MYZAAAAAIfuUlv3QK-wWvwKKKafTxfwPACe'
                                                        ref={captchaRef}
                                                    />
                                                }
                                                
                                            </Col>
                                        </Grid>
                                        <Grid fluid>
                                            <Col xs={24} sm={12} md={12} className="pb-3">
                                                <Button appearance='ghost' style={{width:'100%'}} className='btn-register' 
                                                    //onClick={()=>router.push('/')}
                                                    >{t('buttons.cancel')}</Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} className="pb-3">
                                                <Button appearance='primary' style={{width:'100%'}} className="btn-login" onClick={()=>onSubmit()}>{t('buttons.registerForm')}</Button>
                                            </Col>
                                        </Grid>
                                    </>    
                                :
                                    <Grid fluid>
                                        <Col xs={24} sm={24}  md={12} mdOffset={12} lg={12} lgOffset={6} xl={12} xlOffset={6} className="text-center">
                                            <Loader size="md" content={t('loader.register')} />                                        
                                        </Col>
                                    </Grid>
                                }
                                
                            </Col>
                        </Col>
                    </Grid>
                </Col>
            </Col>
        </Grid>
    )
}

export default Register;