import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick/lib/slider";
import { Col, Grid } from "rsuite";
import $ from 'jquery';

const Blog = ()=>{
    const [post, setPost] = useState([]);
    const [settings, setSettings] = useState({
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: true,
        pauseOnHover: true
    })

    const calculateNumberOfSlidesToShow = ()=>{
        var carouselWidth = $("body").width();
        let setting = settings;

        var numberOfSlides = 0;
        switch (true) {
            case (carouselWidth < 767):
                numberOfSlides = 1;
                break;
            case (carouselWidth < 991):
                numberOfSlides = 2;
                break;
            case (carouselWidth < 1199):
                numberOfSlides = 3;
                break;
            case (carouselWidth > 1200):
                numberOfSlides = 3;
                break;
            default:
                break;
        }

        setting = {
            ...setting,
            slidesToShow: numberOfSlides
        }
        
        setSettings(setting);
    };

    const requestBlogger = ()=>{
        let blogId = '5243185095571619147'
        let url = 'blogs/'+blogId+'/posts';
        
        fetch('https://www.googleapis.com/blogger/v3/'+url+'?key=AIzaSyAIAocGjo9Jx3PI2pMWcxON-ctpNWIyXKk',{
            method:'GET',
            headers: new Headers({
                'Accept'		:'application/json',
                'Content-Type'	: 'application/json'
            })
        }).then(res => {
            if(res.ok){
                return res.json();
            }
        }).then(response => {
            if(response !== undefined){
                const parser = new DOMParser();
                let post = [];

                response.items.forEach((res, i) => {
                    const html = parser.parseFromString(res.content, 'text/html');
                    const img = html.querySelector('img');
                    const span = html.querySelectorAll('span');
                    let content = '';
                    span.forEach((s, i)=>{
                        if(i < 6){
                            content += s.outerText+'\n';
                        }
                    });
                    
                    const image = img ? img.src : '../logo.png';

                    post.push({
                        image: image,
                        title: res.title,
                        url: res.url,
                        content:content
                    });
                });

                calculateNumberOfSlidesToShow();

                setPost(post);
            }
        });
    }
    

    useEffect(()=>{
        window.addEventListener('resize', function(){
            calculateNumberOfSlidesToShow();
        });

        requestBlogger();
    // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid>
            <Col xs={24}>
                <Slider {...settings}>
                    {post.map((p, i)=>
                        <div key={i}>
                            <Grid fluid>
                                <Col xs={22} xsOffset={1} style={{padding:5}}>
                                    <img src={p.image} alt="blog" referrerPolicy="no-referrer" style={{width:'100%', height:250, objectFit:'cover'}}/>
                                </Col>
                                <Col xs={22} xsOffset={1} style={{height:56, marginTop:8, marginBottom:9, textOverflow:'ellipsis', overflow:'hidden'}}>
                                    <a href={p.url} style={{fontSize:18, textDecoration:'none', color:'var(--gris)', cursor:'pointer'}} >{p.title}</a>
                                </Col>
                                <Col xs={22} xsOffset={1} style={{height:88, textOverflow:'ellipsis', overflow:'hidden'}}>
                                    <p style={{fontSize:14,textAlign:'justify'}}>{p.content}</p>
                                </Col>
                            </Grid>
                            
                        </div>
                    )}
                </Slider>
            </Col>
        </Grid>
    )
}

export default Blog;