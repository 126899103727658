import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import $ from 'jquery';
import { Col, Grid, Button, Row } from "rsuite";

import styles from '../../assets/css/Home.module.css';

import CarouselText from "./CarouselText";
import Features from "./Features";
import Evidences from "./Evidences";
import Reports from "./Reports";
import Blog from "./Blog";

import carousel1 from '../../assets/images/carousel1.png';
import carousel2 from '../../assets/images/carousel2.png';
import free from '../../assets/images/free.png';
import basic from '../../assets/images/basic.png';
import premium from '../../assets/images/premium.png';
import { Link } from "react-router-dom";

const Home = ({t, device, i18n})=>{	
    const [heightImg, setImageSize] = useState(800);

	const carousel = [
		{image:carousel1, alt:"carousel"},
		{image:carousel2, alt:"carousel"},
	];
	const [carouselActive, setCarouselActive] = useState(0);

	const imgSize = ()=>{
		let myImg = $(".carousel-img");
		
		let realHeight = myImg.height();

		if(setImageSize !== 0){
			setImageSize(realHeight);
		}
		

		$('.content-carousel').css('height', realHeight);
	}

	const changeCarousel = async (option)=>{		
		let active = carouselActive;
		if(option === 'left'){
			if(active === 0){
				active = carousel.length - 1;
			}else{
				active--;
			}
		}else{
			if(active === carousel.length - 1){
				active = 0;
			}else{
				active++;
			}
		}

		setCarouselActive(active);
	}

    useEffect(()=>{
        window.addEventListener('resize', function(){
            imgSize();
        });

        setTimeout(()=>{
            imgSize();
        }, 300);        
    },[]);

    return (
        <>
            {/* carousel */}	
			<div style={{height:heightImg}} id="start">
				{carousel.map((c, i)=>
					i === carouselActive &&
						<motion.div
							key={i}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5 }}							
						>
							<img src={c.image} alt={c.alt} style={{width:'100%'}} className="carousel-img" />		
							<CarouselText 
                                changeCarousel={changeCarousel} 
                                imgSize={imgSize} 
                                heightImg={heightImg} 
                                t={t}
                                styles={styles}
								lang={i18n.language === 'en' ? 'en' : 'es'}
                            />				
						</motion.div>	
				)}				
			</div>
			
			{device === 'mobile' &&
				<Grid fluid id="text-content-carousel">
					<Row>
						<Col sm={22} smOffset={1} md={20} mdOffset={2} lgHidden xlHidden className={styles.textDescription+' text-center pt-2'}>
							<p>
								{t('pages.home.carousel.content')}
							</p>
						</Col>
					</Row>
				</Grid>
			}

            <Features t={t} styles={styles} device={device} />

			<Evidences t={t} styles={styles} device={device} />
			
			<Reports t={t} styles={styles} />

            <Grid fluid id="technology">
				<Col xs={24}>
					<Col xs={22} xsOffset={1} md={16} mdOffset={4} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer" style={{paddingBottom:'3%'}}>
						<h1 className='text-center' >{t('pages.home.technology.title')}</h1>
						<div className="titleLine"></div>
					</Col>
				</Col>
				<Col xs={24} className={styles.technologyContent}>
					<Col md={22} mdOffset={1} lg={20} lgOffset={2} xl={16} xlOffset={4}>
						<Col xs={24} lg={8}>
							<Col xs={24} sm={20} md={8} lg={24}>
								<h2>{t('pages.home.technology.step')} <span>1</span></h2>
							</Col>
							<Col xs={24} md={16} lg={24}>
								<Col xs={24}>
									<h3>{t('pages.home.technology.one.title')}</h3>
								</Col>
								<Col className={styles.line}></Col>
								<Col xs={24} md={20}lg={20} xl={19}>
									<p>
										{t('pages.home.technology.one.content')}
									</p>
								</Col>
							</Col>							
						</Col>
						<Col xs={24} lg={8}>
							<Col xs={24} sm={20} md={8} lg={24}>
								<h2>{t('pages.home.technology.step')} <span>2</span></h2>
							</Col>
							<Col xs={24} md={16} lg={24}>
								<Col xs={24}>
									<h3>{t('pages.home.technology.two.title')}</h3>
								</Col>
								<Col className={styles.line}></Col>
								<Col xs={24} md={20} lg={22} xl={20}>
									<p>
										{t('pages.home.technology.two.content')}
									</p>
								</Col>
							</Col>							
						</Col>
						<Col xs={24} lg={8}>
							<Col xs={24} sm={20} md={8} lg={24}>
								<h2>{t('pages.home.technology.step')} <span>3</span></h2>
							</Col>
							<Col xs={24} md={16} lg={24}>
								<Col xs={24}>
									<h3>{t('pages.home.technology.three.title')}</h3>
								</Col>
								<Col className={styles.line}></Col>
								<Col xs={24} md={20} lg={22} xl={20}>
									<p>
										{t('pages.home.technology.three.content')}
									</p>
								</Col>
							</Col>
							
						</Col>
					</Col>
				</Col>
			</Grid>

			<Grid fluid id="plans">
				<Col xs={24}>
					<Col xs={22} xsOffset={1} md={16} mdOffset={4} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer" style={{paddingBottom:'3%'}}>
						<h1 className='text-center' >{t('pages.home.plans.title')}</h1>
						<div className="titleLine"></div>
					</Col>
				</Col>
				<Col xs={24} className={styles.plans}>
					<Col md={22} mdOffset={1} lg={22} lgOffset={1} xl={18} xlOffset={3}>
						<Col xs={24} sm={18} smOffset={3} mdOffset={6} md={12} lg={8} className="pe-2 mb-3">
							<Col xs={24} className={styles.container}>
								<Col xs={24} className={styles.features}>
									<Col xs={24} className={styles.header+' '+styles.free}>
										<h2>{t('pages.home.plans.free.title')}</h2>
									</Col>
									<Col xs={24}>
										<h3>{t('pages.home.plans.free.price')}</h3>
									</Col>
									<Col xs={24} className={styles.span}>
										<span>{t('pages.home.plans.by')}</span>
									</Col>
									<Col xs={24} style={{marginTop:'5%', height:170}}>
										<Col xl={20} xlOffset={2}>
											<div>
												<Col xs={2}> <img alt="free" src={free} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.free.facial')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="free" src={free} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.free.location')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="free" src={free} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.free.employees')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="free" src={free} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.free.address')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="free" src={free} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.free.report')}</span> </Col>
											</div>
										</Col>										
									</Col>
									<Col xs={24} className="text-center" style={{paddingTop:'10%', paddingBottom:'10%'}}>
										<Link to={i18n.language !== 'en' ? 'https://links.justcheck.us/sriu' : 'https://links.justcheck.us/DV7o'}>
											<Button appearance='ghost' className="btn-demo">{t('buttons.free')}</Button>
										</Link>										
									</Col>
								</Col>
							</Col>							
						</Col>
						<Col xs={24} sm={18} smOffset={3} mdOffset={6} md={12} lg={8} className="ps-2 pe-2 mb-3">
							<Col xs={24} className={styles.container}>
								<Col xs={24} className={styles.features}>
									<Col xs={24} className={styles.header+' '+styles.basic}>
										<h2>{t('pages.home.plans.basic.title')}</h2>
									</Col>
									<Col xs={24}>
										<h3>{t('pages.home.plans.basic.price')}</h3>
									</Col>
									<Col xs={24} className={styles.span}>
										<span>{t('pages.home.plans.by')}</span>
									</Col>
									<Col xs={24} style={{marginTop:'5%', height:170}}>
										<Col xl={20} xlOffset={2}>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.free')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.employees')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.address')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.report')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.multiple')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.hour')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="basic" src={basic} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.basic.config')}</span> </Col>
											</div>
										</Col>										
									</Col>
									<Col xs={24} className="text-center" style={{paddingTop:'10%', paddingBottom:'10%'}}>
										<Link to={i18n.language !== 'en' ? 'https://links.justcheck.us/66r7' : 'https://links.justcheck.us/mVEq'}>
											<Button appearance='ghost' className="btn-demo">{t('buttons.basic')}</Button>
										</Link>
									</Col>
								</Col>
							</Col>
						</Col>
						<Col xs={24} sm={18} smOffset={3} mdOffset={6} md={12} lg={8} className="ps-2 mb-3">
							<Col xs={24} className={styles.container}>
								<Col xs={24} className={styles.features}>									
									<Col xs={24} className={styles.header+' '+styles.premium}>
										<h4>{t('pages.home.plans.premium.sale')}</h4>
										<h2>{t('pages.home.plans.premium.title')}</h2>
									</Col>
									<Col xs={24}>
										<h3>{t('pages.home.plans.premium.price')}</h3>
									</Col>
									<Col xs={24} className={styles.span}>
										<span>{t('pages.home.plans.by')}</span>
									</Col>
									<Col xs={24} style={{marginTop:'5%', height:170}}>
										<Col xl={20} xlOffset={2}>
											<div>
												<Col xs={2}> <img alt="premium" src={premium} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.premium.basic')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="premium" src={premium} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.premium.visit')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="premium" src={premium} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.premium.photo')}</span> </Col>
											</div>
											<div>
												<Col xs={2}> <img alt="premium" src={premium} style={{width:'100%'}} /> </Col> <Col xs={22}> <span>{t('pages.home.plans.premium.report')}</span> </Col>
											</div>
										</Col>										
									</Col>
									<Col xs={24} className="text-center" style={{paddingTop:'10%', paddingBottom:'10%'}}>
										<Link to={i18n.language !== 'en' ? 'https://links.justcheck.us/wfMF' : 'https://links.justcheck.us/7iQR'}>
											<Button appearance='ghost' className="btn-demo">{t('buttons.premium')}</Button>
										</Link>
									</Col>
								</Col>
							</Col>
						</Col>
					</Col>
				</Col>
			</Grid>

            <Grid fluid id="blog">
				<Col xs={24}>
					<Col xs={22} xsOffset={1} md={16} mdOffset={4} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer" style={{paddingBottom:'3%'}}>
						<h1 className='text-center' >{t('pages.home.blog')}</h1>
						<div className="titleLine"></div>
					</Col>
				</Col>
				<Col xs={24} className={styles.blog}>
					<Col xs={22} xsOffset={1} md={22} mdOffset={1} lg={20} lgOffset={2} xl={16} xlOffset={4}>
						<Blog />
					</Col>
				</Col>
			</Grid>
        </>
    )
}

export default Home;