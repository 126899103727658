import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Grid, Row } from "rsuite";

import leftArrow from '../../assets/images/left-arrow.png';
import rightArrow from '../../assets/images/right-arrow.png';

const CarouselText = ({changeCarousel, heightImg, t, styles, lang})=>{
    const navigate = useNavigate();    

    return(
        <Grid fluid className={styles.contentCarousel+' content-carousel'} style={{height:heightImg}}>
            <Col xs={24} style={{display:'flex', alignItems:'center'}}>
                <Col xs={2} className={styles.arrow} onClick={()=>changeCarousel('left')}>
                    <img src={leftArrow} alt="img-carousel1" style={{width:'30%'}} />
                </Col>
                <Col xs={20}>
                    <Row className={styles.textContainer}>
                        <Col xs={24} sm={20} md={9} mdOffset={2} lg={10} lgOffset={2} xl={9} xlOffset={2} className={styles.textCarousel}>
                            <h1 className='title'>
                                {t('pages.home.carousel.title')}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xsHidden smHidden mdHidden lg={10} lgOffset={2} xl={9} xlOffset={2} className={styles.textDescription}>
                            <p>
                                {t('pages.home.carousel.content')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={12} smOffset={1} md={10} mdOffset={2} lg={10} lgOffset={2} xl={9} xlOffset={2} className="text-end" style={{marginTop:20}}>
                            <Col xs={24} sm={18} lg={12} lgOffset={12}>
                                <Button appearance="primary" className={styles.btnTest+" btn-test"} onClick={()=>navigate('/'+lang+'/register')}>{t('buttons.test')}</Button>
                            </Col>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className={styles.arrow} onClick={()=>changeCarousel('right')}>
                    <img src={rightArrow} alt="img-carousel1" style={{width:'30%'}}/>
                </Col>
            </Col>
        </Grid>
    )
}

export default CarouselText;