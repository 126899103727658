import '../assets/css/form.css';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Grid, Message, Button } from "rsuite";
import ReCAPTCHA from "react-google-recaptcha";
import {isEmail} from  'validator';
import { isValidForm } from '../helper/functions';
import Swal from 'sweetalert2';

const Support = ({t, i18n})=>{
    const navigate = useNavigate();
    const captchaRef = useRef(null); 
    const location = useLocation();
    const [lang, setLang] = useState('');
    const [page, setPage] = useState('');
    const [types, setTypes] = useState([]);
    const [form, setForm] = useState({
        activeAccount:'',
        email:'',
        supportType:'',
        title:'',
        description:''
    });
    const [errorEmail, setErrorEmail] = useState('');
    const [errorForm, setErrorForm] = useState('');
    const [saving, setSaving] = useState('false');
    const [characters, setCharacter] = useState(0);

    const handleChange = (e)=>{
        let value = e.target.value;
        let name = e.target.name;

        let item = form;

        if(name === 'email'){            
            if(value === ''){
                setErrorEmail('');
            }else{
                if(!isEmail(value)){
                    setErrorEmail(t('general.errorEmail'));
                }else{
                    setErrorEmail('');
                }
            }
        }

        if(name === 'title'){
            if(value.length <= 250){
                setCharacter(value.length);
            }else{
                value = value.substring(0, 250);
            }
        }

        item = {
            ...item,
            [name]: value
        };

        setForm(item);
    }

    const getSupportTypes = ()=>{
        let len = i18n.language === 'en' ? 'en' : 'es';

        fetch('https://api.justcheck.us/accounts-api/lookups/support_type/'+len,{
            method:'GET',
            headers: new Headers({
                'Content-Type'	: 'application/json'
            })
        }).then(res => {
            if(res.ok){
                return res.json();
            }else{
                res.text().then(msg=>{
                    var message = JSON.parse(msg);
                    
                    setErrorForm(message.message);

                    setTimeout(()=>{
                        setErrorForm('');
                    }, 6000);
                });
            }
        }).then(response => {
            if(response !== undefined){
                setTypes(response);
            }
        });
    }

    const updatePage = ()=>{
        if(page === ''){
            if(location.pathname.indexOf('/sales') !== -1){
                setPage('sales');
                
                setForm({
                    activeAccount:'false',
                    email:'',
                    supportType:'sales',
                    title:'',
                    description:''
                });
            }else{
                setPage('support');
                setForm({
                    activeAccount:'false',
                    email:'',
                    supportType:'sales',
                    title:'',
                    description:''
                });
            }
        }else{
            if('/'+page !== location.pathname){
                if(location.pathname.indexOf('/sales') !== -1){
                    setPage('sales');
                    
                    setForm({
                        activeAccount:'false',
                        email:'',
                        supportType:'sales',
                        title:'',
                        description:''
                    });
                }else{
                    setPage('support');
                    setForm({
                        activeAccount:'false',
                        email:'',
                        supportType:'sales',
                        title:'',
                        description:''
                    });
                }
            }   
        }        
    }

    const onSubmit = ()=>{
        let token = captchaRef.current.getValue();
        
        setErrorForm('');
        if(token === ''){
            setErrorForm(t('general.errorCaptcha'));
        }else{
            let obj = {
                email: form.email,
				activeAccount: form.activeAccount,
				supportType: form.supportType,
				title: form.title,
				description: form.description
            }

            if(isValidForm('div.form')){
                setSaving('true');
                fetch('https://api.justcheck.us/leads-api/leads',{
					method:'POST',
					body:JSON.stringify(obj),
					headers: new Headers({
						'Content-Type':'application/json',
						'Accept':'application/json'
					})
				}).then(res => {
					if(res.ok){
						return res.json();
					}else{
						setSaving(false);
						res.text().then(msg=>{
							var message = JSON.parse(msg);						
							
                            setErrorForm(message.message);
						});
					}
				}).then(response => {
					if(response !== undefined){
                        Swal.fire({
                            title: t('general.messages.form.title'),
                            icon: 'success',
                            html:t('general.messages.form.content'),
                            showCloseButton: false,
                            showCancelButton: false,
                            confirmButtonColor:'var(--naranja)',
                            confirmButtonText:t('buttons.continue'),
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/');
                            } 
                        });
					}
				});

            }else{
                setErrorForm(t('general.errorFields'));
            }               
        }
    }

    const loadPage = ()=>{
        if(lang !== i18n.language){
            updatePage();
            setLang(i18n.language);
            getSupportTypes();
        }
    }
    
    useEffect(()=>{
        loadPage();
    });

    return(
        <Grid fluid>
            <Col xs={24} className="p-3">
                <Col xs={24} sm={24} md={22} mdOffset={1} lg={18} lgOffset={3} xl={16} xlOffset={4} className="form p-3">
                    <h2 className="title">{page === 'sales' ? t('pages.form.sales') : t('pages.form.support')}</h2>

                    {errorForm !== '' &&
                        <Col xs={24}>
                            <Message type="error">{errorForm}</Message>
                        </Col>
                    }
                    {page === 'support' &&
                        <Col xs={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <span>{t('pages.form.haveAccount')}</span><span style={{color:'red'}}>*</span>
                                <select className='form-control form-control-sm' name="activeAccount" id="activeAccount" value={form.activeAccount} onChange={(e)=>handleChange(e)} required>
                                    <option value="">{t('general.select')}</option>
                                    <option value={false}>{t('general.no')}</option>
                                    <option value={true}>{t('general.yes')}</option>
                                </select>
                            </Col>
                        </Col>
                    }

                    <Col xs={24}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <span>{t('pages.form.email')}</span><span style={{color:'red'}}>*</span>{errorEmail !== '' && <span className='ms-2' style={{color:'red', fontSize:11}}>{errorEmail}</span>}
                            <input className='form-control form-control-sm' name="email" id="email" value={form.email} onChange={(e)=>handleChange(e)} required />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <span>{t('pages.form.supportType')}</span><span style={{color:'red'}}>*</span>
                            <select className='form-control form-control-sm' name="supportType" id="supportType" value={form.supportType} onChange={(e)=>handleChange(e)}
                                disabled={form.activeAccount === 'false'} required
                            >
                                <option value="">{t('general.select')}</option>
                                {types.map((t, i)=>
                                    <option value={t.key} key={i}>{t.value}</option>
                                )}
                            </select>
                        </Col>
                        <Col xs={24}>
                            <span>{t('pages.form.title')}</span><span style={{color:'red'}}>*</span> <span className='ms-2' style={{fontSize:10, color:'#9d9d9d'}}>{t('pages.form.numberTitle')+' '+characters}</span>
                            <input className='form-control form-control-sm' name="title" id="title" value={form.title} onChange={(e)=>handleChange(e)} placeholder={t('pages.form.maxTitle')} required/>
                        </Col>
                        <Col xs={24}>
                            <span>{t('pages.form.description')}</span><span style={{color:'red'}}>*</span>
                            <textarea className='form-control form-control-sm' name="description" id="description" value={form.description} onChange={(e)=>handleChange(e)}
                                rows="7" style={{resize:'none', color:'#000'}} required
                            ></textarea>
                        </Col>
                        <Col xs={24} className="flex justify-content-end mt-3 mb-3">
                            {i18n.language === 'en' ?
                                <ReCAPTCHA 
                                    hl='en'
                                    sitekey='6Ldb1MYZAAAAAIfuUlv3QK-wWvwKKKafTxfwPACe'
                                    ref={captchaRef}
                                />
                            :
                                <ReCAPTCHA 
                                    hl='es'
                                    sitekey='6Ldb1MYZAAAAAIfuUlv3QK-wWvwKKKafTxfwPACe'
                                    ref={captchaRef}
                                />
                            }                            
                        </Col>
                        <Col xs={24} className="pe-0">
                            <Col xs={24} sm={24} md={12} mdOffset={12} lg={12} lgOffset={12} xl={8} xlOffset={16} className="pe-0">
                                <Col xs={24} sm={12} md={12} className="mb-2">
                                    <Button appearance="ghost" className='btn-register' onClick={()=>navigate('/')}>{t('buttons.cancel')}</Button>
                                </Col>
                                <Col xs={24} sm={12} md={12} className="mb-2">
                                    <Button appearance="primary" className='btn-login btn-send' disabled={saving !== 'false'} onClick={()=>onSubmit()}>{t('buttons.send')}</Button>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                    
                </Col>
            </Col>
        </Grid>
    )
}

export default Support;