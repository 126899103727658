import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Grid, Button } from "rsuite";
import { motion } from "framer-motion";

import mx from '../assets/images/mx.jpg';
import us from '../assets/images/us.png';

const Path = props => (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke="hsl(0, 0%, 18%)"
      strokeLinecap="round"
      {...props}
    />
);

const HeaderMovile = ({t, scroll, i18n})=>{
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const menu = [
        {title:'home', id:'start', item:{x:0, y:0, rotate:0},},
        {title:'features', id:"features", item:{x:0, y:0, rotate:0},},
        {title:'technology', id:'technology', item:{x:0, y:0, rotate:0},},
        {title:'plans', id:'plans', item:{x:0, y:0, rotate:0},},
        {title:'blog', id:'blog', item:{x:0, y:0, rotate:0},},
        {title:'industry', id:'industry', item:{x:0, y:0, rotate:0},},
    ];

    const onClickMenu = (id)=>{
        let lang = i18n.language === 'en' ? 'en' : 'es';

        if(location.pathname === '/register'){
            let state = {
                section: id
            };

            let url_string = window.location.href;
            let campaign;

            if(url_string.indexOf('?') !== -1){
                let variable = url_string.split('?')[1];
                campaign = variable.split('=')[1];
            }

            if(location.state !== null){
                if(location.state.campaign){
                    campaign = location.state.campaign;
                }
            }

            if(campaign){
                state = {
                    ...state,
                    campaign: campaign
                };
            }            
            
            if(id === 'industries'){
                navigate('/'+lang+'/'+id, {state: state});
            }else{
                navigate('/'+lang, {state: state});
            }
            
        }else if(location.pathname.length > 2){ 
            let state = {
                section: id
            };

            if(id === 'register' || id === 'industry'){
                navigate('/'+lang+'/'+id, {state: state});
            }else{
                navigate('/'+lang, {state: state});
            }
            
        }else{            
            if(id === 'register'){
                if(location.state !== null){
                    navigate(lang+'/register', {state: {campaign: location.state.campaign}});
                }else{
                    navigate(lang+'/register');
                }
            }else if(id === 'industry'){
                navigate(lang+'/industry');
            }else{
                scroll(id);
            }
            
        }        
    }

    const changeLanguage = (e = null)=>{
        if(e !== null){
            e.preventDefault();
        }        

        let lang = i18n.language;

        let path = location.pathname.split('/');

        if(lang === 'es'){
          i18n.changeLanguage('en');
          navigate('/en'+(path.length > 2 ? '/'+path[2] : ''));
        }else{
          i18n.changeLanguage('es');
          navigate('/es'+(path.length > 2 ? '/'+path[2] : ''));
        }
    }


    useEffect(()=>{
        window.addEventListener('click', function(e){
            var cursorX = e.pageX;
            var cursorY = e.pageY;
            let wx = window.innerWidth;

            if((wx - 80) > cursorX && cursorY > 55){
                setOpen(false);
            }
            
        });

        // eslint-disable-next-line
    },[]);

    return(
        <Grid fluid className={(location.pathname === '/es' || location.pathname === '/en' ? 'home ' : '') + "menu-container"}>
            <Col xs={24} md={24} className="flex align-items-center pt-3">
                <Col xs={6} sm={6} md={6}>
                    <div>
                        <Link to="/" className="brand">
                            <img src="/logo.png" alt="Main logo"/>
                        </Link>
                    </div>
                </Col>
                <Col xs={3} xsOffset={15} sm={2} smOffset={16} md={2} mdOffset={16} style={{textAlign:'center'}}>
                    <motion.nav
                        initial={false}
                        animate={open ? "open" : "closed"}                    
                    >
                        <button onClick={()=>setOpen(!open)} style={{background:'transparent'}}>
                            <svg width="25" height="25" viewBox="0 0 25 25">
                                <Path
                                    variants={{
                                        closed: { d: "M 2 2.5 L 20 2.5" },
                                        open: { d: "M 3 16.5 L 17 2.5" }
                                    }}
                                />
                                <Path
                                    d="M 2 9.423 L 20 9.423"
                                    variants={{
                                        closed: { opacity: 1 },
                                        open: { opacity: 0 }
                                    }}
                                    transition={{ duration: 0.1 }}
                                />
                                <Path
                                    variants={{
                                        closed: { d: "M 2 16.346 L 20 16.346" },
                                        open: { d: "M 3 2.5 L 17 16.346" }
                                    }}
                                />
                            </svg>
                        </button>
                    </motion.nav>
                </Col>
                <motion.div
                    className="menu-movile"
                    animate={open ? {
                        width: '250px',                        
                        visibility:'visible'
                    } : {
                        width:0, 
                        visibility:'hidden'
                    }}
                    style={{background:'#fff'}}
                >
                    <div style={{width:'90%', marginLeft:'5%'}}>
                        <div className="button flag">
                            {i18n.language === 'en' ? 
                                <Link to="#" onClick={(e)=>changeLanguage(e)} title="Change language to Spanish">
                                    {<img src={mx} alt="mx" className="img-fluid"/>}
                                </Link>
                            :
                                <Link to="#" onClick={(e)=>changeLanguage(e)} title="Cambiar idioma a Inglés">
                                    {<img src={us} alt="us" className="img-fluid"/>}
                                </Link>
                            }
                        </div>
                        {menu.map((element, i)=>
                            <div              
                                key={i}          
                                onClick={()=>onClickMenu(element.id)}
                                className="menu-item"
                            >
                                <span>{t('menu.'+element.title)}</span>
                            </div>
                        )}

                        <div className="buttons">
                            <div className="content">
                                <div className="button register">
                                    <Button appearance="ghost" className="btn-register" onClick={()=>onClickMenu('register')}>{t('buttons.register')}</Button>
                                </div>
                                <div className="button register">
                                    <a href="https://app.justcheck.us/#/login">
                                        <Button appearance="primary" className="btn-login">{t('buttons.login')}</Button>
                                    </a>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </motion.div>
            </Col>
        </Grid>
    )
}

export default HeaderMovile;