import React from "react";
import { Col, Grid } from "rsuite";

import nomina from '../../assets/images/nomina.png';
import visita from '../../assets/images/visita.png';
import entradas from '../../assets/images/entradas.png';

const Reports = ({styles, t})=>{
    const reports = 'pages.home.reports';
    return(
        <Grid fluid id="reports">
            <Col xs={24}>
                <Col xs={22} xsOffset={1} md={16} mdOffset={4} lg={14} lgOffset={5} xl={12} xlOffset={6} className="titleContainer">
                    <h1 className='text-center' >{t(reports+'.title')}</h1>
                    <div className="titleLine"></div>
                </Col>
            </Col>
            <Col xs={24} className={styles.reportsContent}>
                <Col xs={24} md={22} mdOffset={1} lg={22} lgOffset={1} xl={18} xlOffset={3}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="mb-3">
                        <Col xs={24} sm={24} md={8} lg={24} xl={24}>
                            <Col xs={24}>
                                <img src={nomina} style={{width:'100%', height:'100%'}} alt="report" />
                            </Col>
                        </Col>                        
                        <Col xs={24} sm={24} md={16} lg={24} xl={24}>
                            <Col xs={24}>
                                <h2>{t(reports+'.payroll.title')}</h2>
                            </Col>
                            <Col xs={24} className={styles.line}></Col>    
                            <Col xs={24}>
                                <p>{t(reports+'.payroll.content')}</p>
                            </Col>
                        </Col>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="mb-3">
                        <Col xs={24} sm={24} md={8} lg={24} xl={24}>
                            <Col xs={24}>
                                <img src={visita} style={{width:'100%', height:'100%'}} alt="report" />
                            </Col>
                        </Col>                        
                        <Col xs={24} sm={24} md={16} lg={24} xl={24}>
                            <Col xs={24}>
                                <h2>{t(reports+'.visit.title')}</h2>
                            </Col>
                            <Col xs={24} className={styles.line}></Col>    
                            <Col xs={24}>
                                <p>{t(reports+'.visit.content')}</p>
                            </Col>
                        </Col>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="mb-3">
                        <Col xs={24} sm={24} md={8} lg={24} xl={24}>
                            <Col xs={24}>
                                <img src={entradas} style={{width:'100%', height:'100%'}} alt="report" />
                            </Col>
                        </Col>                        
                        <Col xs={24} sm={24} md={16} lg={24} xl={24}>
                            <Col xs={24}>
                                <h2>{t(reports+'.photo.title')}</h2>
                            </Col>
                            <Col xs={24} className={styles.line}></Col>    
                            <Col xs={24}>
                                <p>{t(reports+'.photo.content')}</p>
                            </Col>
                        </Col>
                    </Col>
                </Col>
            </Col>
        </Grid>
    )
}

export default Reports;