import '../assets/css/industry.css';
import React from "react";
import { Col, Grid } from "rsuite";

import sales from "../assets/images/industries/sales.png";
import minorista from "../assets/images/industries/retail.png";
import proservices from "../assets/images/industries/services.png";
import franquicias from "../assets/images/industries/franquicia.png";
import construction from "../assets/images/industries/construction.png";
import spa from "../assets/images/industries/spa.png";
import health from "../assets/images/industries/health.png";
import tech from "../assets/images/industries/tech.png";
import school from "../assets/images/industries/college.png";
import events from "../assets/images/industries/events.png";
import hotel from "../assets/images/industries/hotel.png";
import security from "../assets/images/industries/law.png";
import callcenter from "../assets/images/industries/support.png";
import bar from "../assets/images/industries/bar.png";
import outsourcing from "../assets/images/industries/outsource.png";

const Industry = ({t})=>{
    const industries = [
        {title:"sales", image:sales},
        {title:"minorista",  image:minorista},
        {title:"pro-services",  image:proservices},
        {title:"franquicias",  image:franquicias},
        {title:"construction",  image:construction},
        {title:"spa",  image:spa},
        {title:"health",  image:health},
        {title:"tech",  image:tech},
        {title:"school",  image:school},
        {title:"events",  image:events},
        {title:"hotel",  image:hotel},
        {title:"security",  image:security},
        {title:"call-center",  image:callcenter},
        {title:"bar", image:bar},
        {title:"outsourcing",  image:outsourcing},
    ]
    return(
        <Grid fluid>
            <Col xs={24} className="industry p-4 mt-4">
                <Col xs={24} className="p-4">
                    <Col xs={24} sm={24} md={24} lg={9} className="title-container">
                        <h1 className="title">{t('pages.industries.title')}</h1>
                    </Col>

                    <Col xs={24} sm={24} className="mt-3 mb-3 flex justify-content-center">
                        <Col md={24} lg={24} xl={22}>
                            {industries.map((industry, i)=>(
                                <Col xs={24} md={24} lg={12} xl={8} key={i}>                                    
                                    <Col lg={4} xl={4} className="p-2 title-content flex align-items-center">
                                        <img src={industry.image} alt={industry.title} className="img-fluid" />
                                    </Col>
                                    <Col lg={18} xl={20} className="mt-2 title-content flex align-items-center">
                                        <h2>{t('pages.industries.'+industry.title+'.title')}</h2 >
                                    </Col>
                                    <Col style={{width:'100%', border:'1px solid var(--gris)'}}></Col>
                                    <Col xl={24} className="industry-content">
                                        <p style={{padding:4}}>{t('pages.industries.'+industry.title+'.content')}</p>
                                    </Col>
                                </Col>
                            ))}
                        </Col>
                    </Col>                    
                </Col>
            </Col>
        </Grid>
    )
}

export default Industry;