import React from "react";
import { Col, Grid, Row } from "rsuite";

import phone1 from '../../assets/images/phone1.png';
import phone2 from '../../assets/images/phone2.png';
import wave from '../../assets/images/wave.png';

const Evidences = ({t, styles, device})=>{
    const evidences = 'pages.home.evidences';
    return(
        <Grid fluid id="evidence">
            <Col xs={24}>
                <Col xs={24} md={22} mdOffset={1} lg={20} lgOffset={2} xl={16} xlOffset={4} className={styles.evidenceContent}>
                    {device === 'desktop' ?
                        <>
                            <Col lg={10} xl={8} className={styles.description}>
                                <h3>{t(evidences+'.subtitle')}</h3>
                                <h1>{t(evidences+'.title')}</h1>
                                <p>{t(evidences+'.content')}</p>
                            </Col>
                            <Col lg={14} xl={16}>
                                <Col xs={12} className={styles.phone1}>
                                    <img src={phone1} style={{width:'100%', height:'100%'}} alt="evidences" />
                                </Col>
                                <Col xs={12} className={styles.phone2}>
                                    <img src={phone2} style={{width:'100%', height:'100%'}} alt="evidences" />
                                </Col>
                            </Col>
                            <Col xs={24} className={styles.wave}>
                                <img src={wave} style={{width:'100%', height:'100%'}} alt="evidences" />
                            </Col>						
                        </>
                    :
                        <>
                            <Row>
                                <Col xs={24} className="text-center titleContainer">
                                    <h3>{t(evidences+'.subtitle')}</h3>
                                    <h1>{t(evidences+'.title')}</h1>
                                    <div className="titleLine"></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={18} xsOffset={3} sm={14} smOffset={5} md={12} mdOffset={6}>
                                    <Col xs={12} className={styles.phone1}>
                                        <img src={phone1} style={{width:'100%', height:'100%'}} alt="evidences" />
                                    </Col>
                                    <Col xs={12} className={styles.phone2}>
                                        <img src={phone2} style={{width:'100%', height:'100%'}} alt="evidences" />
                                    </Col>
                                </Col>
                            </Row>
                            <Row>                            
                                <Col xs={24} md={22} mdOffset={1} className={styles.wave}>
                                    <img src={wave} style={{width:'100%', height:'100%'}} alt="evidences" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} md={22} mdOffset={1} className={styles.description}>
                                    <p>{t(evidences+'.content')}</p>                                 
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Col>
        </Grid>
    )
}

export default Evidences;