export const isAlpha = (inputtxt)=>{
    return new RegExp(/^[A-Za-z ]+$/).test(inputtxt);
}

export const isValidForm = (element)=>{
	var ctrls = [];
	const select = document.querySelector(element);
    console.log(element, select)
	if(select !== null){
		ctrls = select.querySelectorAll('input, select, textarea');
   	
	    let isFormValid = true;
    	 ctrls.forEach(ctrl => {
	    	if(ctrl.required){
		      	const isInputValid = showCtrlError(ctrl.id);
		      	if (!isInputValid) {
		        	isFormValid = false;
		    	}
		  	}
	    });
	   
	    return isFormValid;
	}

	return true;

};

export const showCtrlError = (id)=>{
	var res = null;
	var control = document.getElementById(id);
    console.log(id)
	if(control !== null){
		if (control.value.trim() === "") {
	        if(control !== null){
	            control.classList.add('error');
	        }
			res = false;
		} else{
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
	    	}
			res = true;
		}
	}

	return res;
};